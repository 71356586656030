import React, {useCallback, useEffect, useState} from "react"
import moment from "moment-timezone"
import chartService from "../../service/chartData"
import Chart from "../Chart/components/Chart"
import {fitWidth} from "react-stockcharts/lib/helper"
import backtestMain from "./backtestHelper"


const token = 'nfo_nifty22dec18200ce'
const interval = '1'
const _AlgoBacktest = React.forwardRef(() => {
  const [chartData, setChartData] = useState(null)
  const mockDispatch = (action) => { console.log(`action: ${action.type} ${action.payload}`) }
  const getChartData = useCallback(async () => {
    // const today = moment().format()
    // const twoDaysAgo = moment().subtract(4, "days").format()
    const start = moment('2022-12-28T09:45:00.000Z').format()
    const end = moment('2022-12-28T09:48:00.000Z').format()
    const chartDataRes = await chartService.getData(start, end, token, interval, false)

    console.log('start: ', start)
    console.log('end: ', end)
    console.log('chartDataRes: ', chartDataRes.data.length)
    setChartData(chartDataRes.data)
  }, [])
  const getChartData2 = useCallback(async () => {
    const chartDataRes = await chartService.getBacktestData()
    const backtestResult = await backtestMain(chartDataRes.data)
    setChartData(backtestResult)
  }, [])
  useEffect(() => {
    // getChartData()
    getChartData2()
  }, [])
  console.log('chartData: ', chartData)
  return (
    <div className="algo-backtest">
      <div className="algo-backtest__header">
        <h1>Algo Backtest</h1>
      </div>
      <div className="algo-backtest__body">
        {
          chartData && (
            <Chart
              data={chartData}
              instrumentName={'instrumentName'}
              interval={interval}
              // width={1200}
              drawings={[]}
              mainDispatch={mockDispatch}
              zoomEnabled={true}
              panEnabled={true}
              type={'hybrid'}
            />
          )
        }
      </div>
    </div>
  )
})

export const AlgoBacktest = fitWidth(_AlgoBacktest)