import axios from "axios"
import {BASE_URL} from "../config"

const getData = (start, end, instrumentName, interval, useHistorical) => {
  return axios.get(BASE_URL + '/tick', {params: {start, end, interval, instrumentName, useHistorical}})
}

const getBacktestData = () => {
  return axios.get(BASE_URL + '/tick/backtest')
}
const chartService = {
  getData,
  getBacktestData
}
export default chartService;
