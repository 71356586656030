import {buyPath, sellPath} from "react-stockcharts/lib/annotation"

const defaultAnnotationProps = {
  onClick: console.log.bind(console),
};
export const longShortAnnotationProps = {
  long: {
    ...defaultAnnotationProps,
    y: ({yScale, datum}) => yScale(datum.low),
    fill: "#006517",
    path: buyPath,
    tooltip: "Go long",
  },
  short: {
    ...defaultAnnotationProps,
    y: ({yScale, datum}) => yScale(datum.high),
    fill: "#FF0000",
    path: sellPath,
    tooltip: "Go short",
  }
}