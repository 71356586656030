import React from "react";
import {BrowserRouter as Router, Route, Switch, useHistory, withRouter} from "react-router-dom";
import Login from "./views/Login"
import Dashboard from "./views/Dashboard"
import ChartDemoHook from "./views/Chart/ChartDemoHook"
import axios from "axios"
import config from "./config"
import Callback from "./views/AuthCallback/Callback"
import Header from "./components/Header/Header"
import GenericError from "./views/GenericError/GenericError"
import {isLocal, isProd} from "./utils"
import {AlgoBacktest} from "./views/AlgoBacktest"

export const setZAuth = (history) => {
  axios.defaults.headers.common['zat'] = localStorage.getItem("zdha_access_token");
  axios.defaults.baseURL = config.BASE_URL
  const appKey = localStorage.getItem("saved_user_id")
  if(!appKey) history.push('/error')
  axios.defaults.headers.common['saved_user_id'] = appKey;
}

const setTitle = () => {
  if(isLocal()) {
    document.title = '(DEV) HB TRADER'
  }
  if(isProd()) {
    document.title = 'HB TRADER'
  }
}
class App extends React.Component{
  constructor(props) {
    super(props);
    setTitle()
    this.state = {
      loggedIn: false,
      instruments: []
    }
    setZAuth(props.history);
  }

  componentDidMount() {
    this.loadInstruments();
  }

  loadInstruments() {
    axios.get('/instruments')
      .then(res => {
        this.setState({instruments: res.data})
        window.MY_INSTRUMENTS = res.data
      })
  }

  render() {
    return (
      <div className={'px-6 bp3-dark dark-mode-bg min-h-screen'}>
        <div>
          {
            this.props.location.pathname !== '/error' ? (
              <Header/>
            ) : ''
          }
          <hr/>
          <div>
            <Switch>
              <Route exact path="/">
                <Dashboard/>
              </Route>
              <Route path="/login">
                <Login/>
              </Route>
              <Route path="/callback">
                <Callback/>
              </Route>
              <Route path="/chart">
                <ChartDemoHook instruments={this.state.instruments}/>
              </Route>
              <Route path="/backtest">
                <AlgoBacktest/>
              </Route>
              <Route path="/error">
                <GenericError/>
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
