import minimal_test from "./strats/triggers/minimal_test"
import strategyAlpha from "./strats/triggers/strategyAlpha"


const backtestMain = (chartData) => {

  // chartData with short and long info
  return backtest(chartData)

}


const backtest = async (chartData) => {
  const copy = [...chartData]
  const len = chartData.length
  let memState = {}
  for(let i = 0; i < len; i++) {
    const tickCache = copy.slice(0, i + 1)
    const current = copy[i]
    let {newMemState} = await strategyAlpha(i, tickCache, {mem: memState}, null, null)
    if(newMemState?.backtestData?.longShort) {
      current.longShort = newMemState.backtestData.longShort
      newMemState.backtestData.longShort = null
    }
    copy[i] = current
    memState = newMemState
  }
  return copy
}

export default backtestMain