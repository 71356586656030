import {RSI, SMA, BollingerBands} from 'technicalindicators'

const RSI_THRESHOLD_COUNT = 7
const RSI_THRESHOLD_OUTOF = 10

function convertTickToTimeFrame(closeValues, timeFrame) {
  let newCloseValues = [];
  for (let i = 0; i < closeValues.length; i += timeFrame) {
    let last = 0;
    //TODO probably can make the loop into one liner
    for (let j = i; j < i + timeFrame && j < closeValues.length; j++) {
      last = closeValues[j];
    }
    newCloseValues.push(last);
  }
  return newCloseValues;
}
const main = async (clock, tickCache, tradeInfo, kc, tt) => {
  try {

    let { mem: memState} = tradeInfo;
    //TODO remove these 2 lines in the end
    let newMemState = {...memState}
    if(!newMemState?.backtestData?.strategyAlpha) {
      newMemState.backtestData = {}
      newMemState.backtestData.strategyAlpha = {}
    }

    const sma = SMA.calculate({period: 20, values: tickCache.map(t => t.close)})
    const rsi = RSI.calculate({period: 14, values: tickCache.map(t => t.close)})
    const rsiSMA = SMA.calculate({period: 14, values: rsi})
    const close15 = convertTickToTimeFrame(tickCache.map(t => t.close), 15)
    const bollingerBands15 = BollingerBands.calculate({period: 20, stdDev: 2, values: close15})
    // console.log('bollingerBands15: ', bollingerBands15)

    const lastTick = tickCache[tickCache.length - 1]
    const ltp = parseFloat(lastTick.close)
    const lastBB = bollingerBands15[bollingerBands15.length - 1]

    const {strategyAlpha} = newMemState.backtestData
    console.log('strategyAlpha: ', strategyAlpha)
    let newStrategyAlpha = {...strategyAlpha}

    if(lastBB && ltp < lastBB.lower && !strategyAlpha.active && !strategyAlpha.position > 0) {
      console.log('setting active')
      newStrategyAlpha.active = true
    }
    if(strategyAlpha.active) {
      console.log('was active')
      let count = 0
      const rsiLastIndex = rsi.length - 1
      const rsiSMALastIndex = rsiSMA.length - 1
      for(let i = 0; i < RSI_THRESHOLD_OUTOF; i++) {
        if(
          rsiSMA.length > rsiSMALastIndex - i &&
          rsi.length > rsiLastIndex - i &&
          rsiSMA[rsiSMALastIndex - i] < rsi[rsiLastIndex - i]
        ) {
          count++
        }
      }
      if(count >= RSI_THRESHOLD_COUNT ) {
        newMemState.backtestData.longShort = "LONG"
        newStrategyAlpha.position = Number.isInteger(newStrategyAlpha.position) ?  newStrategyAlpha + 1 : 1
        newStrategyAlpha.entryPrice = ltp
        newStrategyAlpha.active = false
      }
    }
    if(strategyAlpha.position > 0) {
      if(ltp - strategyAlpha.entryPrice >= 5) {
        newMemState.backtestData.longShort = "SHORT"
        newStrategyAlpha.position = Number.isInteger(newStrategyAlpha.position) ?  newStrategyAlpha - 1 : 0
      }
      if(strategyAlpha.entryPrice - ltp >= 2.5) {
        newMemState.backtestData.longShort = "SHORT"
        newStrategyAlpha.position = Number.isInteger(newStrategyAlpha.position) ?  newStrategyAlpha - 1 : 0
      }
    }
    newMemState.backtestData.strategyAlpha = newStrategyAlpha
    return {
      //TODO refactor later to only return db state. when we do that, we will have to modify all strategies and triggers if applicable
      newMemState,
    }
  } catch (e) {
    console.log('Exception in strategy: ', e)
  }
}
export default main;

