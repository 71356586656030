import {MinimalButton} from "./Button"
import moment from "moment-timezone"
import React, {useContext, useState} from "react"
import {MainContext} from "../ChartDemoHook"
import {Button, HTMLSelect, Radio, RadioGroup} from "@blueprintjs/core"
import InstrumentSelector from "./InstrumentSelector/InstrumentSelector"
import {Link} from "react-router-dom"
const options = [
  {value: '1', label: '1 lot'},
  {value: '2', label: '2 lot'},
  {value: '3', label: '3 lot'},
  {value: '4', label: '4 lot'},
  {value: '5', label: '5 lot'},
  {value: '6', label: '6 lot'},
  {value: '7', label: '7 lot'},
  {value: '8', label: '8 lot'},
  {value: '9', label: '9 lot'},
  {value: '10', label: '10 lots'},
]
const ChartTopControls = ({instruments, chartSettings, chartOrder, onRefreshAll, onAddInstrument, showWarning}) => {
  const {mainDispatch} = useContext(MainContext)
  const step = chartOrder ? chartOrder.step : null
  const selectedInstrument = instruments.find(i => i.instrument_token === chartSettings.token)
  const [orderQuantity, setOrderQuantity] = useState(options[0].value)
  const [ceInstrumentToken, setCeInstrumentToken] = useState('')
  const [ceLimitOffset, setCeLimitOffset] = useState('')
  const [peLimitOffset, setPeLimitOffset] = useState('')
  const [peInstrumentToken, setPeInstrumentToken] = useState('')

  return (
  <div className={'p-5'}>
    <InstrumentSelector instruments={instruments} selectedInstrument={selectedInstrument} mainDispatch={mainDispatch}/>
    {
      showWarning ? (
        <div className={'bg-red-400 p-2 my-4'}>
          <span>The current chart instrument is not part of the trade.</span> {' '}
          <Link onClick={onAddInstrument}>Add to Trade?</Link>
        </div>
      ) : ''
    }
    <div>
      <Button onClick={onRefreshAll}>
        Refresh
      </Button>
      <Button onClick={() => {
        mainDispatch({type: "SET_CHART_TOKEN", token: "256265"})
      }}>
        NIFTY 50
      </Button>
      <Button onClick={() => {
        const futureInstruments = instruments.filter(i => {
          return i.segment === "NFO-FUT" && i.name === "NIFTY"
        })
        let firstExpiry = futureInstruments[0]
        firstExpiry = futureInstruments.reduce((a, c) => {
          const aDate = moment(a.expiry)
          const cDate = moment(c.expiry)
          return aDate.isAfter(cDate) ? c : a
        }, firstExpiry)
        mainDispatch({type: "SET_CHART_TOKEN", token: firstExpiry.instrument_token})
      }}>
        NIFTY FUT
      </Button>
      <Button onClick={() => {
        mainDispatch({type: "SET_CHART_TOKEN", token: "260105"})
      }}>
        BANK NIFTY
      </Button>
      <Button onClick={() => {
        const futureInstruments = instruments.filter(i => {
          return i.segment === "NFO-FUT" && i.name === "BANKNIFTY"
        })
        let firstExpiry = futureInstruments[0]
        firstExpiry = futureInstruments.reduce((a, c) => {
          const aDate = moment(a.expiry)
          const cDate = moment(c.expiry)
          return aDate.isAfter(cDate) ? c : a
        }, firstExpiry)
        mainDispatch({type: "SET_CHART_TOKEN", token: firstExpiry.instrument_token})
      }}>
        BANKNIFTY FUT
      </Button>
      <Button onClick={() => {
        mainDispatch({type: "SET_CHART_TOKEN", token: "315393"})
      }}>
        GRASIM
      </Button>
    </div>
    <div>
      <div className={'mt-2'}>
        <RadioGroup
          inline
          onChange={(e) => {
            //'false' as string to boolean
            if(JSON.parse(e.target.value)) {
              mainDispatch({type: 'SWITCH_TO_LIVE'})
            } else {
              mainDispatch({type: 'SWITCH_TO_HISTORICAL'})
            }
          }}
          selectedValue={chartSettings.liveData.toString()}
        >
          <Radio label="Historical" value="false" />
          <Radio label="Live" value="true" />
        </RadioGroup>

      </div>
      {
        chartSettings.liveData ? (
          <div>
            <MinimalButton onClick={() => {
              mainDispatch({type: "SET_CHART_INTERVAL", interval: '1s'})
            }}>
              1s
            </MinimalButton>
            <MinimalButton onClick={() => {
              mainDispatch({type: "SET_CHART_INTERVAL", interval: '5s'})
            }}>
              5s
            </MinimalButton>
            <MinimalButton onClick={() => {
              mainDispatch({type: "SET_CHART_INTERVAL", interval: '15s'})
            }}>
              15s
            </MinimalButton>
            <MinimalButton onClick={() => {
              mainDispatch({type: "SET_CHART_INTERVAL", interval: '30s'})
            }}>
              30s
            </MinimalButton>
          </div>
        ) : (
          <div>
            <MinimalButton onClick={() => {
              mainDispatch({type: "SET_CHART_INTERVAL", interval: 1})
            }}>
              1m
            </MinimalButton>
            <MinimalButton onClick={() => {
              mainDispatch({type: "SET_CHART_INTERVAL", interval: 5})
            }}>
              5m
            </MinimalButton>
            <MinimalButton onClick={() => {
              mainDispatch({type: "SET_CHART_INTERVAL", interval: 15})
            }}>
              15m
            </MinimalButton>
            <MinimalButton onClick={() => {
              mainDispatch({type: "SET_CHART_INTERVAL", interval: 30})
            }}>
              30m
            </MinimalButton>
            <MinimalButton onClick={() => {
              mainDispatch({type: "SET_CHART_INTERVAL", interval: 60})
            }}>
              60m
            </MinimalButton>
          </div>
        )
      }
    </div>
    <div className={'my-10'}>
      <Button icon={'plus'} onClick={onAddInstrument} >
        Add Instrument To Trade
      </Button>
    </div>
    <div>
      <div className={'emulated-co-market-control mb-12 md:space-x-6 space-y-4'}>
        <Button className="mt-4" onClick={() => mainDispatch({type: 'CREATE_CHART_ORDER', chartOrderType: 'BUY_MARKET_EMULATED_CO', orderQuantity})}>BUY MARKET EMULATED_CO</Button>
        <Button onClick={() => mainDispatch({type: 'CREATE_CHART_ORDER', chartOrderType: 'SELL_MARKET_EMULATED_CO', orderQuantity})}>SELL MARKET EMULATED_CO</Button>
        {
          chartOrder && (chartOrder.type === "BUY_MARKET_EMULATED_CO" || chartOrder.type === "SELL_MARKET_EMULATED_CO")? (
            <>
              <input className="bp3-input" type="text" placeholder="Text input" dir="auto" disabled={step < 0}/>
              <input className="bp3-input mr-4" type="text" placeholder="Text input" dir="auto" disabled={step < 1}/>
              <Button className="mx-2" onClick={() => mainDispatch({type: 'CANCEL_CHART_ORDER'})}>Cancel</Button>
            </>
          ) : ('')
        }
        <HTMLSelect options={options} value={orderQuantity} onChange={(e) => setOrderQuantity(e.target.value)}/>


      </div>
      <div className={'emulated-co-market-control mb-16'}>
        <div className={'mb-2 md:space-x-6 space-y-4'}>
          <Button  className="mt-4" onClick={() => mainDispatch({type: 'CREATE_CHART_ORDER', chartOrderType: 'CE_MARKET_TRIGGER_ORDER2', orderQuantity, instrumentToken: ceInstrumentToken, limitPriceOffset: ceLimitOffset})}>CE_MARKET_TRIGGER_ORDER2</Button>
          <input className="bp3-input" type="text" value={ceInstrumentToken} onChange={(e) => setCeInstrumentToken(e.target.value)} placeholder="CE Instrument Token" dir="auto"/>
          <input className="bp3-input" type="text" value={ceLimitOffset} onChange={(e) => setCeLimitOffset(e.target.value)} placeholder="CE Limit Offset Price" dir="auto"/>
          <HTMLSelect options={options} value={orderQuantity} onChange={(e) => setOrderQuantity(e.target.value)}/>
          {
            chartOrder && chartOrder.type === "CE_MARKET_TRIGGER_ORDER2" ? (
              <>
                <input className="bp3-input" type="text" placeholder="Text input" dir="auto" disabled={step < 1}/>
                <Button onClick={() => mainDispatch({type: 'CANCEL_CHART_ORDER'})}>Cancel</Button>
              </>
            ) : ('')
          }
        </div>
        <div className={'md:space-x-6 space-y-4'}>
          <Button className="mt-4" onClick={() => mainDispatch({type: 'CREATE_CHART_ORDER', chartOrderType: 'PE_MARKET_TRIGGER_ORDER2', orderQuantity, instrumentToken: peInstrumentToken, limitPriceOffset: peLimitOffset})}>PE_MARKET_TRIGGER_ORDER2</Button>
          <input className="bp3-input" type="text" value={peInstrumentToken} onChange={(e) => setPeInstrumentToken(e.target.value)} placeholder="PE Instrument Token" dir="auto"/>
          <input className="bp3-input" type="text" value={peLimitOffset} onChange={(e) => setPeLimitOffset(e.target.value)} placeholder="PE Limit Offset Price" dir="auto"/>
          <HTMLSelect options={options} value={orderQuantity} onChange={(e) => setOrderQuantity(e.target.value)}/>
          {
            chartOrder && chartOrder.type === "PE_MARKET_TRIGGER_ORDER2" ? (
              <>
                <input className="bp3-input" type="text" placeholder="Text input" dir="auto" disabled={step < 1}/>
                <Button onClick={() => mainDispatch({type: 'CANCEL_CHART_ORDER'})}>Cancel</Button>
              </>
            ) : ('')
          }
        </div>
      </div>

      <div className={'emulated-co-market-control mb-4'}>
        <Button onClick={() => mainDispatch({type: 'CREATE_CHART_ORDER', chartOrderType: 'TRIGGER_SQUAREOFF'})}>TRIGGER_SQUAREOFF</Button>
        {

          chartOrder && chartOrder.type === "TRIGGER_SQUAREOFF" ? (
          <>
              <input className="bp3-input" type="text" placeholder="Text input" dir="auto" disabled={step < 0}/>
              <Button onClick={() => mainDispatch({type: 'CANCEL_CHART_ORDER'})}>Cancel</Button>
            </>
          ) : ('')
        }
      </div>

      <div className={'emulated-co-market-control mb-4 space-x-4'}>
        <Button onClick={() => mainDispatch({type: 'SHOW_CREATE_TRADE_MODAL'})}>Create New Trade</Button>
        <Button onClick={() => mainDispatch({type: 'SHOW_ADD_TRIGGER_MODAL'})}>Add Trigger</Button>
      </div>
    </div>
  </div>
  )
}
export default ChartTopControls
