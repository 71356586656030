import { SMA } from 'technicalindicators'


const main = async (clock, tickCache, tradeInfo, kc, tt) => {
  try {

    let { mem: memState} = tradeInfo;
    //TODO remove these 2 lines in the end
    let newMemState = {...memState}

    const sma = SMA.calculate({period: 20, values: tickCache.map(t => t.close)})
    const lastTick = tickCache[tickCache.length - 1]
    const ltp = parseFloat(lastTick.close)

    if(sma[sma.length - 1] > ltp) {
      newMemState.backtestData = {}
      newMemState.backtestData.longShort = "LONG"
    }

    return {
      //TODO refactor later to only return db state. when we do that, we will have to modify all strategies and triggers if applicable
      newMemState,
    }
  } catch (e) {
    console.log('Exception in strategy: ', e)
  }
}
export default main;

