
import React from "react";
import PropTypes from "prop-types";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import { ChartCanvas, Chart, ZoomButtons } from "react-stockcharts";
import {
  BollingerSeries,
  CandlestickSeries,
} from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import {
  CrossHairCursor,
  MouseCoordinateX,
  MouseCoordinateY, PriceCoordinate,
} from "react-stockcharts/lib/coordinates";
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import {
  OHLCTooltip,
} from "react-stockcharts/lib/tooltip";
import { fitWidth } from "react-stockcharts/lib/helper";
import { last } from "react-stockcharts/lib/utils";
import {ClickCallback} from "react-stockcharts/lib/interactive"
import {
  InteractiveYCoordinate,
} from "react-stockcharts/lib/interactive";
import {bollingerBand, rsi, sma} from "react-stockcharts/lib/indicator"
import {Annotate, SvgPathAnnotation} from "react-stockcharts/lib/annotation"
import { longShortAnnotationProps } from "../chartConstants"
import RSISeries from "react-stockcharts/lib/series/RSISeries"

//temp
//
let showBB = true;
const bbStroke = {
  top: "#964B00",
  middle: "#000000",
  bottom: "#964B00",
};

const bbFill = "#4682B4";
let interactiveList = [
  {
    ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate,
    yValue: 17820,
    id: 'abcd',
    draggable: true,
  }
]
class CandleStickChartWithZoomPan extends React.Component {
  constructor(props) {
    super(props);
    this.saveNode = this.saveNode.bind(this);
    this.resetYDomain = this.resetYDomain.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.state = {
      interactiveNodeYValue: 17800
    }
  }
  componentWillMount() {
    this.setState({
      suffix: 1,
    });
  }
  saveNode(node) {
    this.node = node;
  }
  resetYDomain() {
    this.node.resetYDomain();
  }
  handleReset() {
    this.setState({
      suffix: this.state.suffix + 1
    });
  }

  render() {
    const { type, width, ratio, drawings, mainDispatch, zoomEnabled, panEnabled } = this.props;
    const { mouseMoveEvent, panEvent, zoomEvent, zoomAnchor} = this.props;
    const { clamp } = this.props;
    const bb = bollingerBand()
      .merge((d, c) => {d.bb = c;})
      .accessor(d => d.bb);

    const rsiCalculator = rsi()
      .options({ windowSize: 14 })
      .merge((d, c) => {d.rsi = c;})
      .accessor(d => d.rsi);

    const smaRsi14 = sma()
      .id(4)
      .options({ windowSize: 14, sourcePath: "rsi" })
      .merge((d, c) => {d.smaRsi14 = c;})
      .accessor(d => d.smaRsi14);
    const { data: initialData } = this.props;
    const calculatedData = rsiCalculator(smaRsi14(bb(initialData)));
    interactiveList = [
      {...interactiveList[0], yValue: this.state.interactiveNodeYValue}
    ]

    const xScaleProvider = discontinuousTimeScaleProvider
      .inputDateAccessor(d => new Date(d.date));
    const {
      data,
      xScale,
      xAccessor,
      displayXAccessor,
    } = xScaleProvider(calculatedData);

    const start = xAccessor(last(data));
    const end = xAccessor(data[Math.max(0, data.length - 150)]);
    const xExtents = [start, end];

    const margin = { left: 70, right: 70, top: 20, bottom: 30 };

    const height = 500;

    const gridHeight = height - margin.top - margin.bottom;
    const gridWidth = width - margin.left - margin.right;

    const showGrid = true;
    const yGrid = showGrid ? { innerTickSize: -1 * gridWidth, tickStrokeOpacity: 0.2 } : {};
    const xGrid = showGrid ? { innerTickSize: -1 * gridHeight, tickStrokeOpacity: 0.2 } : {};

    const levelDrawings = drawings.filter(d => d.type === "LEVELS")
    const orderDrawings = drawings.filter(d => d.type === "ORDERS")
    const triggerDrawings = drawings.filter(d => d.type === "TRIGGERS")

    return (
      <ChartCanvas ref={this.saveNode} height={height}
                   ratio={ratio}
                   width={width}
                   margin={{ left: 70, right: 70, top: 10, bottom: 190 }}
                   mouseMoveEvent={mouseMoveEvent}
                   panEvent={panEnabled}
                   zoomEvent={zoomEnabled}
                   clamp={clamp}
                   zoomAnchor={zoomAnchor}
                   type={type}
                   seriesName={`MSFT_`}
                   data={data}
                   xScale={xScale}
                   xExtents={xExtents}
                   xAccessor={xAccessor}
                   displayXAccessor={displayXAccessor}
      >
        <Chart id={1}
               yExtents={d => {
                 const yPadding = (d.high - d.low) * 1
                 return [d.high + yPadding, d.low - yPadding]
               }}
        >
          <XAxis axisAt="bottom"
                 orient="bottom"
                 zoomEnabled={zoomEvent}
                 {...xGrid} />
          <YAxis axisAt="right"
                 orient="right"
                 ticks={5}
                 zoomEnabled={zoomEvent}
                 {...yGrid}
          />

          {
            levelDrawings.map(d => (
              <PriceCoordinate
                at="left"
                orient="left"
                price={d.details.price}
                displayFormat={format(".2f")}
                onClick={() => console.log('price coordinate clicked')}
              />
            ))
          }
          {
            triggerDrawings.map(d => (
              <PriceCoordinate
                at="left"
                orient="left"
                price={d.details.price}
                displayFormat={format(".2f")}
                onClick={() => console.log('price coordinate clicked')}
              />
            ))
          }
          {
            orderDrawings.map(d => (
              <PriceCoordinate
                at="left"
                orient="left"
                price={d.details.price}
                lineStroke="#3490DC"
                lineOpacity={0.7}
                stroke="#3490DC"
                strokeWidth={1}
                fill="#FFFFFF"
                textFill="#22292F"
                arrowWidth={7}
                strokeDasharray="ShortDash"
                displayFormat={format(".2f")}
                onClick={() => console.log('price coordinate clicked')}
              />
            ))
          }

          <InteractiveYCoordinate
            enabled={true}
            onDragComplete={(yCoordinateList, moreProps, draggedAlert) => {
              console.log('yCoordinateList: ', yCoordinateList)
              console.log('moreProps: ', moreProps)
              console.log('draggedAlert: ', draggedAlert)
              this.setState({interactiveNodeYValue: draggedAlert.yValue})
            }}
            onDelete={() => console.log('onDelete')}
            yCoordinateList={interactiveList}
          />

          <MouseCoordinateY
            at="right"
            orient="right"
            displayFormat={format(".2f")} />

          <CandlestickSeries />
          <BollingerSeries yAccessor={d => d.bb}
                           stroke={bbStroke}
                           fill={bbFill} />
          <OHLCTooltip origin={[-40, 0]}/>
          <ZoomButtons
            onReset={this.handleReset}
          />
          <Annotate with={SvgPathAnnotation} when={d => d.longShort === "LONG"}
                    usingProps={longShortAnnotationProps.long} />
          <Annotate with={SvgPathAnnotation} when={d => d.longShort === "SHORT"}
                    usingProps={longShortAnnotationProps.short} />
          <ClickCallback
            onClick={ (info, e) => {
              if(e) {
                console.log('e:', e)
              }
              const price = info.chartConfig.yScale.invert(info.mouseXY[1]).toFixed(2)
              console.log('price: ', price)
              mainDispatch({type: "CHART_PRICE_RECEIVED", price})
            } }
            onDoubleClick={ (moreProps, e) => { console.log("onDoubleClick", moreProps, e); } }
            onContextMenu={ (moreProps, e) => { console.log("onContextMenu", moreProps, e); } }
          />
        </Chart>
        <Chart id={2}
               yExtents={d => d.volume}
               height={150} origin={(w, h) => [0, h - 150]}
        >
          {/*<YAxis*/}
          {/*  axisAt="left"*/}
          {/*  orient="left"*/}
          {/*  ticks={5}*/}
          {/*  tickFormat={format(".2s")}*/}
          {/*  zoomEnabled={zoomEvent}*/}
          {/*/>*/}

          <MouseCoordinateX
            at="bottom"
            orient="bottom"
            displayFormat={timeFormat("%H:%M %p")} />
          <MouseCoordinateY
            at="left"
            orient="left"
            displayFormat={format(".4s")} />

          {/*<BarSeries yAccessor={d => d.volume} fill={(d) => d.close > d.open ? "#6BA583" : "#FF0000"} />*/}
        </Chart>
        <Chart id={3}
               yExtents={[0, 100]}
               height={225} origin={(w, h) => [0, h - 10]}
        >
          <XAxis axisAt="bottom" orient="bottom" showTicks={false} outerTickSize={0} />
          <YAxis axisAt="right"
                 orient="right"
                 tickValues={[30, 50, 70]}/>
          <MouseCoordinateY
            at="right"
            orient="right"
            displayFormat={format(".2f")} />

          <RSISeries yAccessor={d => d.rsi} />
          <RSISeries yAccessor={d => d.smaRsi14} />
        </Chart>
        <CrossHairCursor />
      </ChartCanvas>
    );
  }
}

CandleStickChartWithZoomPan.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  ratio: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};

CandleStickChartWithZoomPan.defaultProps = {
  type: "hybrid",
  mouseMoveEvent: true,
  panEvent: true,
  zoomEvent: true,
  clamp: false,
};

CandleStickChartWithZoomPan = fitWidth(CandleStickChartWithZoomPan);

export default CandleStickChartWithZoomPan;
